// Angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

// Proyecto
import { constants } from "../environment/constants";
import { catchError, tap } from 'rxjs/operators';
import { Puerto } from "src/app/models/puerto";
import { TipoAccion } from "src/app/models/tipoAccion";
import { CartaPorte } from "src/app/models/cartaPorte";
import { CartaPorteDetalle } from "src/app/models/cartaPorteDetalle";
import { Accion } from "src/app/models/accion";
import { CondicionRecibo } from "../models/condicionRecibo";
import { CamionDescarga } from "../models/camionDescarga";
import { Especie } from "../models/especie";
import { Noticia } from "../models/noticia";
import { AuthResponse } from '../models/authResponse';

@Injectable()
export class AuthService {
    constructor(
        public http: HttpClient
    ) {}

    login(usuario: string, clave: string): Observable<AuthResponse> {
        const loginPayload = {
            username: usuario,
            password: clave,
        };

        return this.http
            .post<AuthResponse>(
                `${constants.urlBase}/auth/sign-in`,
                loginPayload
            )
            .pipe(
                tap((response) => {
                    // Guardo el objeto en la sesión

                    localStorage.setItem("accesoActivo", JSON.stringify(response));
                    localStorage.setItem('leftMenu', JSON.stringify(true));            

                    // Manejar la respuesta si es necesario
                    console.log("Login successful:", response);
                }),
                catchError((error) => {
                    // Manejar el error si es necesario
                    console.error("Login failed:", error);
                    throw error; // Re-lanzar el error para que pueda ser manejado por el llamador
                })
            );
    }

    /**
     * Obtengo la posicion mappeada (TODO: Le voy a pasar los entregadores en formato: cuit_entre1, cuit_entre2, ... , cuit_entreN)
     */
    getPosicion = () =>
        this.http.get(`${constants.urlBase}/carta-porte/posicion`).pipe(
            map((posicionItemsResp: any) =>
                posicionItemsResp.map((itemResp) => {
                    const cloneItemResp = Object.assign({}, itemResp);
                    if (
                        cloneItemResp.estado.estado === 7 &&
                        cloneItemResp.porteEstado === 3
                    ) {
                        cloneItemResp.porteEstado = 5;
                        cloneItemResp.estadoPosiReal = "Desviado";
                    }

                    return new CartaPorte(cloneItemResp);
                })
            )
        );

    /**
     * Obtengo la posicion mappeada (TODO: Le voy a pasar los entregadores en formato: cuit_entre1, cuit_entre2, ... , cuit_entreN)
     */
    getPosicionPuertos = () =>
        this.http.get(`${constants.urlBase}/carta-porte/posicion-puertos`).pipe(
            map((posicionItemsResp: any) =>
                posicionItemsResp.map((itemResp) => {
                    const cloneItemResp = Object.assign({}, itemResp);
                    if (
                        cloneItemResp.estado.estado === 7 &&
                        cloneItemResp.porteEstado === 3
                    ) {
                        cloneItemResp.porteEstado = 5;
                        cloneItemResp.estadoPosiReal = "Desviado";
                    }

                    return new CartaPorte(cloneItemResp);
                })
            )
        );

    /**
     * Obtengo la descarga en determinada fecha
     * @param fechaDesde En formato dd/mm/aaaa
     * @param fechaHasta En formato dd/mm/aaaa
     */
    getHistoria = (fechaDesde) => (fechaHasta) =>
        this.http
            .get(
                `${constants.urlBase}/carta-porte/historia-by-date-range/${fechaDesde}/${fechaHasta}`
            )
            .pipe(
                map((posicionItemsResp: any) =>
                    posicionItemsResp.map((itemResp) => {
                        const cloneItemResp = Object.assign({}, itemResp);

                        if (
                            cloneItemResp.estado.estado === 7 &&
                            cloneItemResp.porteEstado === 3
                        ) {
                            cloneItemResp.porteEstado = 5;
                            cloneItemResp.estadoPosiReal = "Desviado";
                        }

                        return new CartaPorte(cloneItemResp);
                    })
                )
            );

    /**
     * Obtengo la data necesaria para el excel
     */
    getDataExcel = (tipoTabla) => (fechaDesde?) => (fechaHasta?) =>
        this.http
            .get(
                `${constants.urlBase}/carta-porte/data-excel/${tipoTabla}${
                    tipoTabla === "historia"
                        ? "?fechaDesde=" +
                          fechaDesde +
                          "&fechaHasta=" +
                          fechaHasta
                        : ""
                }`
            )
            .pipe(
                map((dataExcel: any) =>
                    dataExcel.map((itemResp) => new CartaPorteDetalle(itemResp))
                )
            );

    /**
     * Este metodo descarga un archivo zip desde el back-end con las imagenes de las Cartes de porte de la posicion
     */
    descargarZIPImagenesPosicion(
        horaDesde: string,
        horaHasta: string,
        codDestino: string,
        codDestinatario: string
    ): Observable<any> {
        let url = `${constants.urlBase}/carta-porte/imagenes/posicion?horaDesde=${horaDesde}&horaHasta=${horaHasta}`;
        url += (codDestino ? "&destino="+codDestino : "");
        url += (codDestinatario ? "&destinatario="+codDestinatario : "");
        return this.http.get(url, { responseType: "blob" });
    }

    /**
     * Este metodo descarga un archivo zip desde el back-end con las imagenes de las Cartes de porte del historial
     */
    descargarZIPImagenesDescargas(
        fechaDesde: string,
        fechaHasta: string,
        codDestino: String,
        codDestinatario: string
    ): Observable<any> {
        let url = `${constants.urlBase}/carta-porte/imagenes/descarga?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`;
        url += (codDestino ? "&destino="+codDestino : "");
        url += (codDestinatario ? "&destinatario="+codDestinatario : "");
        return this.http.get(url, { responseType: "arraybuffer" });
    }

    descargarImagenCP(nroCP: string) {
        let url = `${constants.urlBase}/carta-porte/download/${nroCP}`;
        return this.http.get(url, { responseType: "arraybuffer" });
    }

    /**
     * Obtengo lista de destinos
     */
    getDestinos = () =>
        this.http
            .get(`${constants.urlBase}/carta-porte/destinos`)
            .pipe(
                map((data: any) => data.map((itemResp) => new Puerto(itemResp)))
            );

    /**
     * Setea una nueva accion, agregando el registro en Actualizaciones y cambiando el estaod e la carta
     * TODO: Sería bueno setear las condiciones de abajo en el backend
     */
    setAccion =
        (carta: CartaPorte) =>
        (datosAccion: {
            puertoNuevo: Puerto;
            comentario: string;
            tipoAccion: TipoAccion;
        }) =>
            this.http.post(`${constants.urlBase}/carta-porte/nueva-accion`, {
                portePrefijo: carta.portePrefijo,
                porteNro: carta.porteNro,
                cuitEntre: carta.entregador.cuit,
                porteCodPuerto: carta.porteCodPuerto,
                porteVagon: carta.porteVagon,
                nuevoEstado:
                    datosAccion.tipoAccion.tipo === 1
                        ? 10
                        : datosAccion.tipoAccion.tipo === 2
                        ? 11
                        : datosAccion.tipoAccion.tipo === 4
                        ? 8
                        : 0,
                puertoViejo: carta.puertoDestino.ptoCodinterno
                    ? carta.puertoDestino.ptoCodinterno
                    : 0,
                puertoNuevo:
                    datosAccion.tipoAccion.tipo === 2
                        ? datosAccion.puertoNuevo &&
                          datosAccion.puertoNuevo.ptoCodinterno
                            ? datosAccion.puertoNuevo.ptoCodinterno
                            : 0
                        : 0,
                comentario: datosAccion.comentario,
                tipoAccion: datosAccion.tipoAccion.tipo,
                porteTurno: carta.porteTurno,
                codmerca:
                    carta.especie && carta.especie.codmerca
                        ? carta.especie.codmerca
                        : 0,
                puertoNuevoDesc:
                    datosAccion.tipoAccion.tipo === 2
                        ? datosAccion.puertoNuevo &&
                          datosAccion.puertoNuevo.ptoCodinterno
                            ? datosAccion.puertoNuevo.ptoRazon
                            : ""
                        : "",
            });

    /**
     * Obtengo una carta de la historia por su numero
     */
    getHistoriaPorNroOrPatente = (nroCarta) =>
        this.http
            .get(`${constants.urlBase}/carta-porte/historia/${nroCarta}`)
            .pipe(
                map((cartasEncontradas: any) =>
                    cartasEncontradas.map(
                        (itemResp) => new CartaPorteDetalle(itemResp)
                    )
                )
            );

    /**
     * Obtener el historial de acciones del usuario
     */
    getHistorialAcciones = (fechaDesde: string) => (fechaHasta: string) =>
        this.http.get<Accion[]>(
            `${constants.urlBase}/usuario/historial-acciones?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`
        );

    /**
     * Obtengo condiciones de recibo
     */
    getCondicionesRecibo = () =>
        this.http.get<CondicionRecibo[]>(
            `${constants.urlBase}/carta-porte/condiciones-recibo`
        );

    /**
     * Obtengo camiones a la descarga
     */
    getCamionesDescarga = () =>
        this.http.get<CamionDescarga[]>(
            `${constants.urlBase}/carta-porte/camiones-descarga`
        );

    /**
     * Obtengo todas las especies
     */
    getEspecies = () =>
        this.http.get<Especie[]>(`${constants.urlBase}/carta-porte/especies`);

    /**
     * Obtener el historial de acciones del usuario
     * @param fechaDesde Formato yyyyMMddHHmmss. Ejemplo: 20180917140000 (17/09/2018 14:00:00)
     */
    getHistorialNoticias = (fechaDesde: string) =>
        this.http.get<Noticia[]>(
            `${constants.urlBase}/usuario/historial-noticias?fechaDesde=${fechaDesde}`
        );

    /**
     * Setea una nueva accion, agregando el registro en Actualizaciones y cambiando el estaod e la carta
     */
    setEnTramite = (carta: CartaPorte, newState: boolean) =>
        this.http.post(`${constants.urlBase}/carta-porte/carta-en-tramite`, {
            portePrefijo: carta.portePrefijo,
            porteNro: carta.porteNro,
            cuitEntre: carta.entregador.cuit,
            porteCodPuerto: carta.porteCodPuerto,
            porteVagon: carta.porteVagon,
            enTramite: newState ? 1 : 0,
        });
}
